.contentBySofia{
    max-width: 129.0rem;
    margin: 0 auto;
    padding: 0 3rem;
}

.inicialBySofia .contentText{
    display: grid;
    grid-template-columns: 0.5fr 1fr;
    grid-template-rows: 1fr;
    grid-column-gap: 2.0rem;
    grid-row-gap: 0px;
    align-items: flex-start;
    padding: 1.5rem 0 5.0rem 0;
}

.inicialBySofia .contentText .div71{
    grid-area: 1 / 1 / 2 / 2;
}

.inicialBySofia .contentText .div72{
    grid-area: 1 / 2 / 2 / 3;
}


.inicialBySofia img{
    max-width: 42.0rem;
    height: auto;
    width: 100%;
}


.bySofiaFlex1, .bySofiaFlex2, .bySofiaFlex3{
    display: flex;
    flex-direction: row;
    gap: 2.5rem;
    padding: 0 0 5.0rem 0;
}

.imgContent{
    max-width: 41rem;
    width: auto;
    height: auto;
}
.imgContent img{
   width: 100%;
   height: auto;
}

.imgContent1{
    max-width: 62rem;
    width: auto;
    height: auto;
}

.imgContent1 img{
    width: 100%;
    height: auto;
}


@media (max-width: 750px) {
    .inicialBySofia .contentText{
        grid-template-columns: 1fr;
        grid-template-rows: auto auto;
        align-items: center;
        margin-top: .5rem;
    }
    .inicialBySofia .contentText .div71{
        grid-area: 2 / 1 / 3 / 2;
    }
    .inicialBySofia .contentText .div72{
        grid-area: 1 / 1 / 2 / 2;
    }

    .inicialBySofia img{
        max-width: 67.0rem;
        height: auto;
        width: 100%;
    }

    .bySofiaFlex1, .bySofiaFlex2, .bySofiaFlex3{
        flex-direction: column;
    }
    
    .imgContent{
        max-width: 67rem;
    }
    
    .imgContent1{
        max-width: 67rem;
    }

}
