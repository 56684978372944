.areaTitle{
    min-height: 20.0rem;
    background-color: #001F28;
    color: #fff;
    display: -webkit-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2.5vh;
}

.areaTitle h1{
    text-transform: uppercase;
}

.titleServices{
    display: -webkit-flex;
    flex-direction: row;
    align-items: center;
    gap: 2.5vw;
}

.titleServices span{
    font-family: 'GothicStd-Light','Courier New', Courier, monospace;
    font-size: 2rem; 
    margin-top: -0.5rem;
}
.titleBar{
    text-align: left;
    width: 15vw;
    color: #fff;
    display: block;
    height: 0.05rem;
}



@media (max-width: 500px) {
    .areaTitle{
        gap: 5vh;
    }
    .titleServices span{
        font-size: 1.6rem;
    }
    .titleBar{
        width: 35vw;
    }
}