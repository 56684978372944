.contentPoten{
    display: -webkit-flex;
    flex-direction: column;
    justify-content: space-around;
}


.poten{
    display: -webkit-flex;
    flex-direction: row;
    padding-top: 5.0rem;
    align-items: center;
    gap: 6.0rem;
}

.poten div+div{
    text-align: left;
}


.marcas{
    display: -webkit-flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
}

.imgsMarcas{
    align-items: center;
    justify-items: center;
}

.compañias{
    width: 100%;
    height: auto;
}

.nameMarcas{
    width: 85vw;
}

.nameMarcas  div{
    font-size: 2.2rem;
    font-family: "GothicStd-Light" !important;
    text-align: right;
    margin-right: 2.5rem;
}

.nameMarcas  h2{
    text-align: center;
}



@media (max-width: 1090px) {

    .poten{
        flex-direction: column;
        gap: 1rem;
        margin-top: 1rem;
    }
    .poten div+div{
        text-align: center;
    }
    .contentPoten{
        justify-content: center;
        gap: 4rem;
    }

    .marcas{
        flex-direction: column-reverse;
        align-items: center;
        gap: 1rem;
    }
    .nameMarcas{
        width: 60vw;
    }
    .imgsMarcas{
        width: 100%;
    }
    .nameMarcas div{
        text-align: center;
        font-size: 2rem;
        margin: 0;
    }
}


@media (max-width: 500px) {
    .contentPoten{
        gap: 10lvh;
    }
    .poten{
        grid-area: 1 / 1 / 2 / 2;
        padding-top: 1rem;
        margin-top: 0;
    }
    .marcas{
        grid-area: 2 / 1 / 3 / 2;
        text-align: center;
    }
    .nameMarcas div{
        font-size: 1.8rem;
        margin: 0;
    }
    .nameMarcas{
        display: flex;
        flex-direction: column;
        gap: 1rem;
        width: 100%;
    }
    
}

@media (max-width: 370px) {
    .nameMarcas div{
        font-size: 1.7rem;
    }
}