.footer{
    background-color: #151515;
    width: 100%;
    min-height: 15.0rem;
    max-height: 20.0rem;
    color: #fff;
}

.contentFooter{
    max-width: 131.0rem;
    height: 100%;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 0px;
    padding: 2rem 0;
    align-items: center;
    justify-items: center;
}

small{
    font-family: "Roboto Slab";
    font-size: 1.6rem;
}

.linksFooter{
    display: -webkit-flex;
    flex-direction: row;
    justify-content: center;
    gap: 2rem;
}


@media (max-width: 500px) {
    .footer{
        height: auto;
        max-height: fit-content;
    }
    .contentFooter{
        grid-template-columns: 1fr;
        grid-template-rows: repeat(2, 1fr) 0.5fr;
    }
    small{
        font-size: 1.2rem;
    }
    .div41{
        grid-area: 3 / 1 / 4 / 2;
        padding-bottom: 2.0rem;
    }
}