.contentText{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    align-items: flex-start;
    margin-top: 3rem;  
}

.div71{
    grid-area: 1 / 1 / 2 / 2;
}

.div72{
    grid-area: 1 / 2 / 2 / 3;
    font-size: 1.8rem;
    font-family: 'GothicStd-Light','Courier New', Courier, monospace;
    position: sticky;
    top: .5rem;
}

.imgDiv71{
    width: 100%;
    height: auto;
    padding-right: 1.0rem;
}

.titleDiv72{
    font-size: 2.4rem;
    font-family: "GothicStd-Heavy",'Courier New', Courier, monospace;
    padding-bottom: 2.0rem;
}

.div72 li{
    line-height: 2.2rem;
    margin-left: 2.0rem;
}

.buttelt{
    list-style: disc;
}


.serviceDiv72{
    padding-top: 3.0rem;
}

.servicePara{
    font-size: 1.8rem;
    font-family: "GothicStd-Heavy",'Courier New', Courier, monospace;
}

.servicePara span{
    font-family:  'GothicStd-Light','Courier New', Courier, monospace;
}

.bar1{
    margin: 2.0rem 0;
}

.bar2{
    margin: 2.0rem 0;
}


@media (max-width: 1090px) {
    .contentText{
        grid-template-columns: 1fr;
        grid-template-rows: 0.5fr 0.5fr;
        align-items: center;
        margin-top: .5rem;
    }
    .div71{
        grid-area: 2 / 1 / 3 / 2;
    }
    .div72{
        grid-area: 1 / 1 / 2 / 2;
        position: inherit; 
    }

    .titleDiv72{
        font-size: 2rem;
    }
    .div72{
        font-size: 1.8rem;
    }
    .servicePara{
        font-size: 1.8rem;
    }

    .imgDiv71{
        padding-right: 0;
    }
}

@media (max-width: 500px) {
    .contentText{
        margin-top: 0;
    }
    .titleDiv72{
        font-size: 1.8rem;
    }
    .div72{
        font-size: 1.7rem;
    }
    .servicePara{
        font-size: 1.7rem;
    }
}