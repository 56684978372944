.estudioContent{
    max-width: 129.0rem;
    margin: 0 auto;
    padding: 0 1.0rem;
}

.mapSliceImgs{
    padding-top: 5.0rem;
    padding-bottom: 9.0rem;
    display: -webkit-flex;
    flex-direction: column;
    gap: 5.0rem;
}

.mapSliceImgs img{
    width: 100%;
    height: auto;
}


@media (max-width: 1090px) {
    .estudioContent{
        padding: 0 5.0rem;
    }
}


@media (max-width: 500px) {
    .estudioContent{
        padding: 0 2.0rem;
    }
}
