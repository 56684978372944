.mainAboutUs >div{
    max-width: 131.0rem;
    display: -webkit-flex;
    flex-direction: column;
    gap: 3.0rem;
    margin: 0 auto;
    padding: 0 2.0rem;
    color: #000;
}




.contentCaro{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 0.5fr;
    grid-column-gap: 4.0rem;
    grid-row-gap: 0px;
    padding: 5.0rem 0 5.0rem 0;
}


.div51{
    grid-area: 1 / 1 / 2 / 2; 
}

.div51 img, .div61 img{
    width: 100%;
    height: auto;
}

.caro{
    text-align: left;
    grid-area: 1 / 2 / 2 / 3;
}

.div61{
    grid-area: 1 / 2 / 2 / 3;
}


.ky{
    position: sticky;
    top: .5rem;
}

.caro h3{
    font-family: "GothicStd-Heavy", "FrankGoth", system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 3.4rem;
}

.caro p{
    font-size: 2.2rem;
    margin-top: 2rem;
}

.caro p{
    padding-left: .5rem;
}


@media (max-width: 1200px) {
    .caro h3{
        font-size: 3rem;
    }
}

@media (max-width: 1090px) {
    .mainAboutUs h1{
        font-size: 3rem;
    }
    .caro h3{
        font-size: 2.4rem;
    }
    .caro p{
        font-size: 2rem;
    }
}


@media (max-width: 700px) {
    .contentCaro{
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: auto auto;
        grid-column-gap: 0px;
        grid-row-gap: 2.0rem;
        padding: 1.0rem 0 1.0rem 0;
    }

    .div51, .div61{
        grid-area: 2 / 1 / 3 / 2;
    }
    .caro{
        text-align: center;
        grid-area: 1 / 1 / 2 / 2;
    }
}

@media (max-width: 500px) {
    .mainAboutUs > div{
        padding: 0;
    }
    .caro h3{
        font-size: 2rem;
    }
    .caro p{
        font-size: 1.8rem;
        margin-top: 2.0rem;
    }
}


@media (max-width: 370px) {
    .caro h3{
        font-size: 1.9rem;
    }
    .caro p{
        font-size: 1.7rem;
        margin-top: 2.0rem;
    }
}


