.contectContact{
    display: grid;
    grid-template-columns: 1fr 0.9fr;
    grid-template-rows: 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    border-radius: 5.0rem;
    max-width: 129.0rem;
    min-height: 80vh;
    height: auto;
    margin: 0 auto;
    background-color: #F1EFEF;
    box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.5);
}

.div91{
    grid-area: 1 / 1 / 2 / 2; 
    padding: 3.0rem 2.0rem 2.0rem 4.0rem;
}

.div91 h1{
    text-transform: uppercase;
    font-size: 8rem;
}

.div92{
    grid-area: 1 / 2 / 2 / 3;
    background: url("https://res.cloudinary.com/dpsobnvtv/image/upload/v1686411229/Frenzy/Minimalism-Islam_cpnn0f.jpg");
    background-position: center;
    background-size: cover;
    border-radius: 0 5.0rem 5.0rem 0;
    width: 100%;
    height: 100%;

}

.parent1{
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(4, 0.2fr);
    grid-column-gap: 0px;
    grid-row-gap: 0rem;
    padding-top: 2.0rem;
}

.div101{grid-area: 1 / 1 / 2 / 2;}
.div102{grid-area: 2 / 1 / 3 / 2;}
.div103{grid-area: 3 / 1 / 4 / 2;}
.div104{ grid-area: 4 / 1 / 5 / 2;
    display: -webkit-flex;
    justify-content: center;
    align-items: center;}
.div105{grid-area: 5 / 1 / 6 / 2;
    display: -webkit-flex;
    justify-content: center;
    align-items: center;
}


label{
    display: block; 
    font-size: 2.2rem;
    padding-bottom: .25rem;
    font-family: 'GothicStd-Light','Courier New', Courier, monospace;
    margin-top: .5rem;
  }

.parent1 input{
    font-size: 2.2rem;
    width: 100%;
    margin: 1.0rem 0;
    font-family: 'GothicStd-Light', 'Courier New', Courier, monospace;
    border-radius: 1.0rem;
    border: none;
    border-bottom: 1px solid #000000;
    padding: .5rem;
}

.parent1 input:focus{
    outline: 2px solid #70E5FF;
}

#mensaje{
    resize: none;
    height: 25.0rem;
    width: 100%;
    margin-top: 1.5rem;
    border-radius: 1.0rem;
    padding: .5rem;
    border: none;
    border-bottom: 1px solid #000000;
    font-family: 'GothicStd-Light','Courier New', Courier, monospace;
}

#mensaje:focus{
    outline: 2px solid #70E5FF;
}

#submit{
    font-size: 2.2rem;
    width: fit-content;
    padding: 1.3rem 1.5rem 1.5rem 1.5rem;
    cursor: pointer;
    font-family: "GothicStd-Heavy",'Courier New', Courier, monospace;
}

.error{
    color: red;
  }


.loader-container {
    width: 4.0rem;
    height: 4.0rem;
    display: -webkit-flex;
    justify-content: center;
    align-items: center;
}

.spinner {
    width: 4.0rem;
    height: 4.0rem;
    border: 8px solid;
    border-color: #000 #f3f3f3 #000 #f3f3f3;
    border-radius: 100%;
    animation: spin-anim 1.2s linear infinite;
}

@keyframes spin-anim {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@media (max-width: 1200px) {
    .div91 h1{
        font-size: 7rem;
    }
}

@media (max-width: 1090px) {
    .div91 h1{
        font-size: 6rem;
    }

    label{
        font-size: 2rem;
    }
    .parent1 input{
        font-size: 2rem;
    }
    #submit{
        font-size: 2rem;
    }
}



@media (max-width: 900px) {
    .contectContact{
        grid-template-columns: 1fr;
        grid-template-rows: 35.0rem auto;;
        height: min-content;
    }
    .div91{
        grid-area: 2 / 1 / 3 / 2;
        padding: 3.0rem;
    }

    .div91 h1{
        text-align: center;
    }
    .div92{
        grid-area: 1 / 1 / 2 / 2;
        border-radius: 5.0rem 5.0rem 0 0;
    }
}

@media (max-width: 500px) {
    .contectContact{
        grid-template-columns: 1fr;
        grid-template-rows: 240px auto;
    }
    .div91{
        padding: 3.0rem;
    }
    .div91 h1{
        font-size: 5rem;
    }
    label{
        font-size: 1.8rem;
    }
    .parent1 input{
        font-size: 1.8rem;
    }
    #submit{
        font-size: 1.8rem;
    }
}

@media (max-width: 370px) {
    .div91 h1{
        font-size: 4rem;
    }
    label{
        font-size: 1.7rem;
    }
    .parent1 input{
        font-size: 1.7rem;
    }
    #submit{
        font-size: 1.7rem;
    }

}