@import url('https://fonts.googleapis.com/css2?family=Chivo+Mono:ital,wght@0,100;0,200;0,300;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,500;1,600;1,700;1,800&family=Montserrat:wght@100;400;700&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,400;1,500;1,600&family=Roboto+Slab:wght@200;400;700&display=swap');;

@font-face {
  font-family: "Clearface";
  src: url("./assects/fonts/ClearfaceITCbyBT-Regular.woff") format('woff'),
      url("./assects/fonts/ClearfaceITCbyBT-Regular.ttf") format('truetype');
}

@font-face {
  font-family: "Clearface Italic";
  src: url("./assects/fonts/ClearfaceITCbyBT-RegularItalic.woff") format('woff'),
      url("./assects/fonts/ClearfaceITCbyBT-RegularItalic.ttf") format('truetype');
}

@font-face {
  font-family: "Haas disp";
  src: url("./assects/fonts/HaasGrotDisp-55Roman.woff") format('woff'),
      url("./assects/fonts/HaasGrotDisp-55Roman.ttf") format('truetype');
}

@font-face {
  font-family: "GothicStd-Heavy";
  src: url("./assects/fonts/SackersGothicStd-Heavy.otf") format('opentype');
}

@font-face {
  font-family: "GothicStd-Light";
  src: url("./assects/fonts/SackersGothicStd-Light.otf") format('opentype');
}

@font-face {
  font-family: "FrankGoth";
  src: url("./assects/fonts/FrankGothMT-ExtraCondensed.woff") format('woff'),
      url("./assects/fonts/FrankGothMT-ExtraCondensed.ttf") format('truetype');
}



*{
  box-sizing: border-box;
}



html{
  font-size: 62.5% !important;
}

@media (max-width: 1500px) {
  html{
    font-size: 41.5% !important;
  }
}

/* @media (max-width: 768px) {
  html{
    font-size: 41.5% !important;
  }
} */


html {
  overflow-y: scroll;
  overflow-x: hidden;
  width: 100%;
  scroll-snap-type: y mandatory;
  scroll-behavior: smooth;
  scroll-padding: 0 0 5vh 0;
}

body {
  margin: 0 auto;
  font-family: 'GothicStd-Light',"Clearface", "FrankGoth",-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1, h2, h3, h4, h5{
  font-family: "FrankGoth", "Clearface",-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif ;
  font-size: 2rem;
}

h1, h2{
  font-size: 8rem;
}

h3{
  font-size: 3.4rem;
}

p{
  font-family: 'GothicStd-Light';
  font-size: 2.2rem;
  hyphens: auto;
  text-transform: lowercase !important;
}

pre{
  font-family: 'GothicStd-Light';
  font-size: 2.2rem;
  hyphens: auto;
}


span, p{
  line-height: 1.2;
}


a{
  text-decoration: none;
  color: inherit;
}

@media (max-width: 1200px) {
  h1, h2{
    font-size: 7rem;
  }

  .greenH3{
    font-size: 7rem;
  }
  
  h3{
    font-size: 3rem;
  }
}



@media (max-width: 1090px) {

  h1, h2{
    font-size: 6rem;
  }
  
  h3{
    font-size: 2.4rem;
  }

  .greenH3{
    font-size: 6rem;
  }
  
  p, pre{
    font-size: 2rem;
  }
}


@media (max-width: 500px) {
  
  h1, h2{
    font-size: 5rem;
  }
  
  h3{
    font-size: 1.8rem;
  }

  .greenH3{
    font-size: 5rem;
  }
  
  p{
    font-size: 1.8rem;
  }
}

@media (max-width: 370px) {
  
  h1, h2{
    font-size: 4rem;
  }
  
  h3{
    font-size: 4rem;
  }
  
  p{
    font-size: 1.7rem;
  }
}




