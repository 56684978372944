
.buttonContainer{
    cursor: pointer;
    width: fit-content;
}

.button{
    width: fit-content;
    border-radius: 1.5rem;
    padding: 2.5rem 2.5rem 2.0rem 2.5rem;
    background-color: #DAE9F2;
   
    box-shadow: inset 0px 0px 10px #000000;
}



.button span{
    font-family:"GothicStd-Heavy" ,"GothicStd-Light",'Courier New', Courier, monospace;
    font-size: 2rem;
    line-height: 0.5;
    text-align: center;
}

@media (max-width: 1090px) {
    .button span{
        font-size: 1.8rem;
    }
}


@media (max-width: 500px) {
    .button span{
        font-size: 1.6rem;
    }
}

@media (max-width: 370px) {
    .button span{
        font-size: 1.5rem;
    }
}
