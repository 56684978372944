.mainAboutUs{
    background-color: #F1EFEF;
}

.contentAboutUsTitle{
    min-height: 24.0rem;
    background-color: #001F28;
    color: #fff;
    display: -webkit-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.contentAboutUsTitle h1{
    text-align: center;
}


@media (max-width: 700px) {
    .mainAboutUs{
        padding: 2.0rem ;
    }

    .contentAboutUsTitle{
        min-height: 20.0rem;
        padding: 0 3rem;
    }
}


@media (max-width: 500px) {
    .mainAboutUs{
        padding: 5rem 3rem;
    }
}
