
.root{
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
}

.App {  
  text-align: center;
  align-items: center;  
  margin: 0 auto;
  width: 100%;
  background: linear-gradient(-45deg, #387280, #5AB7CC, #70E5FF, #fff, #BDF3FF, #9CEDFF, #4FDFFF, #5AB7CC);
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;
  scroll-behavior: smooth;
  scroll-padding: 100vh;
}
@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}


.principalBanner{
  scroll-snap-align: start;
  scroll-snap-stop: always;
  max-height: 100vh;
  min-height: 100vh;
  margin-bottom: 50vh;
  width: 100%;
}

.contents, .contentsLastSection{
  margin: 0 auto;
  min-height: 100vh;
  height: auto;
  scroll-snap-align: start;
  scroll-snap-stop: always;
  max-width: 150rem;
  padding: 3rem 2rem;
  display: -webkit-flex;
  text-align: center;
  justify-content: center;

}


.contentsLastSection{
  height: auto;
  min-height: 100vh;
  padding-top: 2.5vh;
  flex-direction: column;
  max-width: 100%;
  gap: 5vh;
  padding: 0 0;
  justify-content: flex-end;
  scroll-snap-stop: always;
}



