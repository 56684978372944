.mainDesiderata{
    padding: 1.5rem 0 3.0rem 0;
    background-color: #F1EFEF;
}


@media (max-width: 500px) {
    .mainDesi .titleServices span{
        width: 12.5rem;
    }
}

