.contactBar{
    height: 16.0rem;
    width: 100%;
    background-color: #001F28;
    display: -webkit-flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: #fff;
    gap: 15vw;
}

.contactBar .button{
    color: #000;
    border-radius: 0px;
}

.contactBar h3{
    font-size: 8rem;
}


@media (max-width: 1090px) {
    .contactBar{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: 1fr;
        grid-column-gap: 0px;
        grid-row-gap: 0px; 
        justify-items: center;
    }
    .div31{
        grid-area: 1 / 1 / 2 / 2;
    }
    .div32{
        grid-area: 1 / 2 / 2 / 3;
    }
    .contactBar h3{
        font-size: 6rem;
        margin-top: 1.0rem;
        text-align: center;
    }
    .contactBar .button span{
        font-size: 1.6rem;
    }
}

@media (max-width: 500px) {
    .contactBar{
        gap: 2rem;
    }
    .contactBar h3{
        font-size: 5rem;
        margin-top: 1rem;
    }
    .contactBar .button span{
        font-size: 1.2rem;
        text-align: center;
    }
}

@media (max-width: 370px) {

    .contactBar h3{
        font-size: 4rem;
    }

}