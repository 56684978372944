.anchorCont {
    width: 100%;
}



.gridMainProjects{
    display: grid;
    grid-template-columns: 20.0rem 1fr;
    grid-template-rows: 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    width: 120.0rem;
    min-height: 25.0rem;
    margin: 0 auto;
    background-color: #fff;
    border-radius: 3.0rem;
    cursor: pointer;
}

.div131{
    grid-area: 1 / 1 / 2 / 2;
    border-radius: 3.0rem;
    background-position: center;
    background-size: cover;
}

.div132{
    grid-area: 1 / 2 / 2 / 3;
    padding: 2.0rem 2.5rem;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.div132 h2{
    font-size: 5rem;
    text-transform: uppercase;
}

.div132 li{
    list-style: none;
    padding: 1rem 0;
}
.liProjects{
    font-size: 2.2rem;
    text-transform: lowercase;
}


@media (max-width: 1200px) {
    .div132 h2{
        font-size: 4.5rem;
    }
}

@media (max-width: 1090px) {

    .div132 h2{
        font-size: 4rem;
    }
    .liProjects{
        font-size: 2rem;
    }
    .gridMainProjects{
        width: 100%;
    }
}

@media (max-width: 650px) {
    .div132  li::before{
        content: "";
        margin-left: -0.5rem;
    }
    .div132 li{
        padding: 0.5rem 0;
    }

    .div132 h2{     
        text-align: center;
    }
    .gridMainProjects{
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 40rem;  
        grid-column-gap: 0px;
        grid-row-gap: 0px;
        width: 100%; 
    }
    .div131{

        grid-area: 2 / 1 / 3 / 2;
    }
    
    .div132{
        grid-area: 1 / 1 / 2 / 2;
        text-align: center;
        padding: 2.5rem;
    }
}

@media (max-width: 500px) {
    .div132 h2{
        font-size: 3rem;
    }

    .liProjects{
        font-size: 1.8rem;
    }   
}

@media (max-width: 370px) {
    .div132 h2{
        font-size: 2rem;
    }
    .liProjects{
        font-size: 1.7rem;
    }
}