.mainContact{
    background: #F1EFEF;
    height: auto;
    padding: 10rem 0;
}


@media (max-width: 900px) {
    .mainContact{
        padding: 10.0rem 2.0rem;
    }
}