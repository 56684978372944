    .containerProjects{
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-evenly;
}

.projectsGrid{
    display: -webkit-flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
    row-gap: 2.0rem;
    column-gap: 1.0rem;
    max-width: 131.0rem;
    margin: 0 auto;
    margin-top: 2.0rem;
}

.buttonMoreProjects{
    margin-top: 2.5rem;
    display: -webkit-flex;
    justify-content: center;
}



@media (max-width: 500px) {
    .containerProjects{
        justify-content: center;
        gap: 5vh;
    }
}
