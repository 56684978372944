
.contentServices{
    display: -webkit-flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.textServices h2{
    padding-bottom: 2rem;
}


.services{
    margin: 0 auto;
    display: -webkit-flex;
    flex-direction:  row;
    align-items: center;
    gap: 2rem;
}

.selectService{
    width: fit-content;
}

.containerService{
    max-height: 50rem;
    width: fit-content;
    margin: 0;
}


.parent2{
    display: -webkit-flex;
    flex-direction: column;
    height: fit-content;
    width: 100%;
}

.parent2 p{
    font-family: "GothicStd-Light";
    font-size: 2.2rem;
    text-align: end;
    padding-top: 1.55rem;
}
.parent2 p+p{
    padding-top: 0rem;
}

.parent2 h2{
    text-align: end;
    font-size: 8rem;
    letter-spacing: -1px;
    text-transform: uppercase;
}

.div22{
    display: -webkit-flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    gap: 15rem;
}


.imgCards{
    height: 4.8rem;
    width: auto;
    max-height: 5.6rem;
}

@media (max-width: 1200px) {
    .parent2 h2{
        font-size: 5.5rem;
    }
}



@media (max-width: 1090px) {
    .contentServices{
        justify-content: center;
        gap: 5vh;
    }
    .services{
        gap: 5vh;
        flex-direction: column; 
    }

    .textServices h2{
        padding-bottom: 1rem;
    }
    .parent2{
        margin-top: 2rem;
        width: 100%;
        min-height: 33rem;
    }
    .parent2 p{
        font-size: 2rem;
        text-align: center;
    }
    .parent2 h2{
        font-size: 6rem;
        text-align: center;
        padding-bottom: 1rem;
    }
    .div22{
        justify-content: center;
    }
}

@media (max-width: 500px) {
    .contentServices{
        width: 100%;
    }
    .imgCards{
        height: 3.8rem;
        width: auto;
        max-height: 4.6rem;
    }

    .textServices h2{
        padding-bottom: 5rem;
    }

    .parent2{
        margin-top: 2rem;
        width: 100%;
        min-height: 23rem;
    }
    .parent2 p{
        font-size: 1.8rem;
    }
    .parent2 h2{
        font-size: 5rem;
    }
    .div22{
        gap: 10rem;
        justify-content: center;
    }
}

@media (max-width: 370px) {
    .imgCards{
        height: 3.2rem;
        width: auto;
        max-height: 4.6rem;
    }

    .parent2{
        margin-top: 1.0rem;
        width: 100%;
        min-height: 23.0rem;
    }
    .parent2 p{
        font-size: 1.7rem;
    }
    .parent2 h2{
        font-size: 4rem;
    }
    .div22{
        gap: 10.0rem;
        justify-content: center;
    }
}