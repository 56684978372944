
.testContainers{
    min-height: 28rem;
    height: fit-content;
    width: fit-content;
}

.containerTest{
    width: 100%;
    height: 100%;
}

.preContainerTest{
    width: 25rem;
    height: 25rem;
    margin: 0 auto;  
}

.cardTest{
    max-width: 131rem;
    max-height: 50rem;
    min-height: 25rem;
    height: auto;
    border-radius: 3rem;
    background-color: #FFF;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    transition: height 2s;
}

.content{
    min-height: 28rem;
    padding: 3rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    justify-content: center;
    align-items: center;
}

.content h2{
    font-family: "GothicStd-Heavy";
    font-size: 2.6rem;
    text-transform: uppercase;
    margin-bottom: -.2rem;
}

.contentTitle{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.content span{
    color: #4F4F4F;
    font-size: 2.2rem;
    font-family: "Clearface",'Courier New', Courier, monospace;
    font-weight: 700;

}

.content span+span{ 
    font-family:"Clearface Italic",'Courier New', Courier, monospace;
}

.content p {
    font-size: 2.4rem;
    padding-top: .5rem;
    text-transform: lowercase;
}




@media (max-width: 1090px) {
    .cardTest{
        min-height: 24rem;
    }
    .testContainers{
        min-height: 24rem;
    }
    .content{
        min-height: 24rem;
    }
    .content h2{
        font-size: 1.8rem;
    }

    .content span{
        font-size: 1.8rem;
    }
    .content p {    
        font-size: 1.8rem;
    }
    
}


@media (max-width: 500px) {
    .testContainers{
        min-height: 31.0rem;
    }
    .content{
        padding: 3.0rem 1.5rem;
    }
    .content h2{
        font-size: 1.8rem;
    }

    .content span{
        font-size: 1.8rem;
    }
    .content p {    
        font-size: 1.6rem;
    }

}


@media (max-width: 370px) {
    .content h2{
        font-size: 1.8rem;
    }

    .content span{
        font-size: 1.8rem;
    }
    .content p {    
        font-size: 1.6rem;
    }
}


