.balls{
    display: -webkit-flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 1.0rem;
    margin-top: 4.0rem;
}

.masTestimonios{
    padding: 4.0rem 2.0rem 2.0rem 2.0rem;
}

.masTestimonios .buttonContainer{
    margin: 0 auto;
}

.parentCarusel>h2{
    margin-bottom: 3.5rem;
    margin-top: 2.0rem;
}


.testShowCase{
    display: -webkit-flex;
    flex-direction: row;
    justify-content: center;
    margin: 0 auto;
    padding: 0 2.0rem;
    gap: 1.0rem;
    min-height: 230px;
}



@media (max-width: 1090px) {
    .testShowCase{
        margin-top: 5.0rem;
        padding: 0 2.0rem;
    }

    .parentCarusel{
        margin-top: 2.0rem;
    }

}

@media (max-width:  790px) {
    .parentCarusel>h2{
        margin-bottom: 5.0rem;
    }
}

@media (max-width: 500px) {
    .masTestimonios{
        padding-top: 2.0rem;
        padding-bottom: 0;
    }
    .testShowCase{
        margin-top: 0rem;
    }
    .parentCarusel>h2{
        margin-bottom: 2.0rem;
    }
    .balls{
        margin-top: 4.0rem;
    }
}