
.serviceCard{
    width: 60rem;
    font-family: "GothicStd-Heavy";
    border-radius: 3.0rem;
    max-height: 40.0rem;
    padding: 2.0rem 2.0rem 1.7rem 2.0rem;
    background-color: #DAE9F2;
    box-shadow: inset 0px 0px 10px #000000;
    border: none;
    margin: 1.4rem 0;
}

.parent {
    display: grid;
    grid-template-columns: 0.2fr 1fr;
    grid-template-rows: 0.2fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    }
    
    /* .div1 { grid-area: 1 / 1 / 2 / 2; 
        display: -webkit-flex;
        align-items: center;
        justify-content: center; } */
    .div122 { grid-area: 1 / 1 / 2 / 3;
        display: -webkit-flex;
        align-items: center;
        justify-content: center; }

.div122 span{
    font-size: 2rem;
    text-transform: uppercase;
}


@media (max-width: 1090px) {
    .serviceCard{
        width: 80vw;
    }
    .div122 span{
        font-size: 1.9rem;
    }
}

@media (max-width: 500px) {
    .serviceCard{
        padding: 1.0rem 1.0rem .7rem 1.0rem;
    }
    .div122 span{
        font-size: 1.7rem;
    }
}

@media (max-width: 400px) {
    .serviceCard{
        padding: 1.5rem 1.0rem 1.2rem 1.0rem;
    }
    .div122 span{
        font-size: 1.6rem;
    }
}