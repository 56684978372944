.contentDesiderata{
    max-width: 129.0rem;
    margin: 0 auto;
    padding: 0 3.0rem;
}

.desiderataFlex1, .desiderataFlex2{
    display: flex;
    flex-direction: row;
    gap: 2.5rem;
    padding: 0 0 5.0rem 0;
    
}

.desiderataFlex1{
    justify-content: center;
}

.desiContent{
        max-width: 63.0rem;
        width: auto;
        height: auto;
}

.desiContent img{
    width: 100%;
   height: auto;
}


@media (max-width: 750px) {
    .desiderataFlex1, .desiderataFlex2{
        flex-direction: column;
    }
    .desiContent{
        max-width: 67.0rem;
    }
}

