.contentMainProjects{
    max-width: 131.0rem;
    margin: 0 auto;
}

.contenProjects{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 2.0rem;
    justify-content: center;
}


@media (max-width: 500px) {
    .contenProjects{
        gap: 5rem;
    }
}