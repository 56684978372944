.mainSapura{
    background-color: #F1EFEF;
    padding: 3.0rem 0;
}



@media (max-width: 750px) {
    .sarapura .titleServices span{
        text-align: center;
    }
}