

.mainProjects{
    padding: 3.0rem;
    background-color:#F1EFEF;
}

.contentProjectsTitle{
        min-height: 24.0rem;
        background-color: #001F28;
        color: #fff;
        display: -webkit-flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
}

.contentProjectsTitle h1{
    text-align: center;
}

@media (max-width: 700px) {
    .contentProjectsTitle{
        min-height: 20.0rem;
    }
}

@media (max-width: 500px) {
    .mainProjects{
        padding: 5rem 3rem;
    }
}
