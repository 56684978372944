
.container{
    min-width: 37.0rem;
    height: 33.0rem;
    background-color: #fff;
    border-radius: 3.0rem;
}

.container  a{
    color: black;
    text-transform: none;
    text-decoration: none;
    border-radius: 3.0rem;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.card{
    border-radius: 3.0rem;
    width: 100%;
    height: 100%;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.containerImg{
    width: 100%;
    height: 100%;
}

.card .img{
    width: 100%;
    height: 100%;
    border-radius: 3rem;
    background-position: center;
    background-size: cover;
}

.text{
    padding: 0 1.5rem 2.0rem 1.5rem;
    transform: translateY(-60px) !important ;
}

.text h3{
    padding: 1rem 0;
    font-size: 2rem;
    font-family: "GothicStd-Light",'Courier New', Courier, monospace;
}

.text span{
    font-family: "GothicStd-Light",'Courier New', Courier, monospace;
    color: #656565;
    font-size: 2rem;
}

/* 
@media (min-height: 600px) and (max-height: 700px) and (max-width: 1300px) {
    .container{
        min-width: 27vw;
        height: 34vh;
    }

    .text{
        padding: 0 15rem 10rem 15rem;
        transform: translateY(-7.0rem) !important;
    }
} */

@media (min-width: 1500px) {
    
    .text{
        padding: 0 1.5rem 2.0rem 1.5rem;
        transform: translateY(-80px) !important ;
    }

}



@media (max-width: 1090px) {
    .text h3, .text span{
        font-size: 2rem;
    }
}

@media (max-width: 800px) {
    .container{
        /* 37.0rem * 0.85 = 31.45rem */
        min-width: 31.45rem; 
        /* 33.0rem * 0.85 = 28.05rem */
        height: 28.05rem;
    }

    .text{
        transform: translateY(-45px) !important ;
    }
}

@media (max-width: 690px) {
    .container{
        /* 31.45rem * 0.85 = 26.74rem */
        min-width: 26.74rem;
        /* 28.05rem * 0.85 = 23.87rem */
        height: 23.87rem;
    }
}


@media (max-width: 590px) {
    .container{
        /* 26.74rem * 0.85 = 22.72rem */
        min-width: 22.72rem;
        /* 23.87rem * 0.85 = 20.12rem */
        height: 20.12rem;
    }
    .text{
        padding: 0 1rem 1.5rem 1rem;
        transform: translateY(-40px) !important ;
    }
}


@media (max-width: 500px) {
    .text h3, .text span{
        padding: 1.2rem  0 0.5rem 0;
        font-size: 1.8rem;
    } 
}

@media (max-width: 370px) {
    .text h3, .text span{
        font-size: 1.7rem;
    } 
}