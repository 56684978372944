.contentSapura{
    max-width: 129.0rem;
    margin: 0 auto;
    padding: 0 3.0rem;
}


.sapuraFlex{
    display: flex;
    flex-direction: row;
    gap: 1.5rem;
    padding: 0 0 5.0rem 0;
}

.sapContent1{
    max-width: 129.0rem;
    width: auto;
    height: auto;
}

.sapContent1 img{
    width: 100%;
    height: auto;
}


.sapContent2{
    display: flex;
    justify-content: center;
    max-width: 41.0rem;
    width: auto;
    height: auto;
}

.sapContent2 img{
    width: 100%;
    height: auto;
}

.sapContent2 video{
    max-width: 41.0rem;
    min-width: 25.0rem;
    width: 100%;
    height: auto;
}


.sapContent3{
    max-width: 62.0rem;
    width: auto;
    height: auto;
}

.sapContent3 img{
    width: 100%;
    height: auto;
}

@media (max-width: 1090px) {
    .inicialSapura .contentText{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: 1fr;
        grid-column-gap: 2.0rem;
        grid-row-gap: 0px;
        align-items: flex-start;
        margin-top: 3.0rem;
    }
    .inicialSapura .contentText .div71{
        grid-area: 1 / 1 / 2 / 2;
     }
    
     .inicialSapura .contentText .div72{
        grid-area: 1 / 2 / 2 / 3;
     }
}


@media (max-width: 750px) {
    .inicialSapura .contentText{
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr auto;
        grid-column-gap: 0px;
        grid-row-gap: 2.0rem;
    }

    .inicialSapura .contentText .div71{
        grid-area: 2 / 1 / 3 / 2;
    }
    .inicialSapura .contentText .div72{
        grid-area: 1 / 1 / 2 / 2;
    }

    .sapuraFlex{
        flex-direction: column;
    }

    .sapContent1, .sapContent2, .sapContent3{
        max-width: 100%;
    }

    .sapContent2 video{
        max-width: none;
        min-width: none;
        width: 100%;
    }
}
