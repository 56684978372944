.typewriter{
    font-size: 10.2rem;
    width: 60vw;
    height: 90vh;
    max-height: 90vh;
    display: -webkit-flex;
    align-items: flex-end;
    text-align: start;
    padding-left: 2.5rem;
    padding-bottom: 5vh;
}

.Typewriter__wrapper{
    line-height: 0.02rem;
    letter-spacing: -0.5rem;
}


@media (max-width: 1090px) {
    .typewriter{
        font-size: 9.2rem;
    }
}

@media (max-width: 790px) {
    .typewriter{
        font-size: 8.2rem;
    }
}

@media (max-width: 500px) {
    .Typewriter__wrapper{
        letter-spacing: -0.1rem;
    }
    .typewriter{
        padding-left: 0.5rem;
        margin-bottom: 3.0rem;
        height: 85vh;
        width: 90vw;
    }
}