.header{
    width: 100%;
    height: 12.0rem;
    position: sticky;
    top: 0;
    z-index: 1;
    transition: transform 1s ease;
}

.hiddenNav{
    transform: translateY(-100%);
}



.firstNav{
    box-shadow: none;
}

.color{
    background-color: #fff !important;
}

.stiky{
    position: sticky;
    top: 0;
    z-index: 1;
    background-color: #fff;
}

.noStiky{
    top: -12rem;
}

.navbar{
    display: -webkit-flex;
    justify-content: space-between;
    height: 100%;
    margin: 0 auto 2.5rem auto;
    padding: 0 2.5rem;
    align-items: center;
}


.logo{
    width: 8.0rem;
    height: auto;
}

.links{
    display: -webkit-flex;
    justify-content: center;
    align-items: center;
    gap: 3.0rem;
}

.links a{
    font-size: 1.8rem;
    text-decoration: none;
    color: #000;
    font-family: "GothicStd-Heavy";
}

.links a:hover{
    font-weight: 600;
    color: #5AB7CC;
}

.currentPage a{
    font-weight: 600;
    color: #5AB7CC;
}


@media (max-width: 900px) {
    .contentLinksSmaller{
        background-color: #fff;
        box-shadow: 0px 16px 12px 0px rgba(0, 0, 0, 0.09);
    }
    .linksSmaller{
        display: -webkit-flex;
        flex-direction: column;
        padding-top: 1.0rem;
        gap: 2.0rem;
        align-items: center;
    }
    .linksSmaller a {
        font-size: 1.8rem;
        text-decoration: none;
        color: #000;
        font-family: "GothicStd-Heavy";
    }
    .currentPage a{
        font-weight: 600;
        color: #5AB7CC;
    }
    .navbar{
        margin-bottom: 0;
    }
    .socialsLinks{
        display: -webkit-flex;
        flex-direction: row;
        justify-content: center;
        gap: 1.0rem;
        padding-bottom: 1.0rem;
    }

}


@media (max-width: 500px) {
    header{
        height: 8.0rem;
    }
    .logo{
        width: 4.0rem;
    }

    .linksSmaller a {
        font-size: 1.4rem;
    }

    .linkLogo{
        width: 3.0rem !important;
    }
}