.testiContent{
    max-width: 131.0rem;
    padding: 5.0rem 2.0rem 5.0rem 2.0rem;
    margin: 0 auto;
    display: -webkit-flex;
    gap: 3.0rem;
    flex-direction: column;

}

#emilia, #agustina, #monserrat {
    display: grid;
    grid-template-columns: 0.5fr repeat(2, 1fr);
    grid-template-rows: 0.5fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    align-items: center;
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border-radius: 3.0rem;
    padding: 3.0rem;
    justify-items: center;
}

#emilia p, #agustina p, #monserrat p{
    font-size: 2.6rem;
    text-transform: lowercase;
}


#emilia h2, #agustina h2, #monserrat h2{
    font-family: "GothicStd-Heavy";
    font-size: 2.2rem;
    text-transform: uppercase;
}

#emilia span, #agustina span, #monserrat span{
    color: #4F4F4F;
    font-size: 2rem;
    font-weight: 700;
    font-family: "Clearface",'Courier New', Courier, monospace;
}

#emilia span>span, #agustina span>span, #monserrat span>span{
    font-family:"Clearface Italic",'Courier New', Courier, monospace;
}


.titleDiv112{
    display: flex;
    flex-direction: column;
    gap: 2rem;
}


    
    .div111 { grid-area: 1 / 1 / 2 / 2;
                width: 20.0rem;
                height: auto;
            }

    .div111 img{
        width: 100%;
        height: auto;
    }
    .div112 { grid-area: 1 / 2 / 2 / 4; 
            display: flex;
            flex-direction: column;
            gap: 2rem;}
    .div113 { grid-area: 2 / 3 / 3 / 4; }


    @media (max-width: 1090px) {
        #emilia, #agustina, #monserrat {
            grid-template-columns: 1fr;
            grid-template-rows: 0.5fr 1fr;
            grid-row-gap: 2.0rem; 
            justify-items: center;
            text-align: center;
        }

        .div111 { grid-area: 1 / 1 / 2 / 2;
                    padding: 0;
                    border-radius: .5rem;}
        .div112 { grid-area: 2 / 1 / 3 / 2;}

        #monserrat .div111{
            height: 15.0rem;
            width: auto;
        }
        #emilia h2, #agustina h2, #monserrat h2{
            font-size: 1.8rem;
        }
        #emilia p, #agustina p, #monserrat p{
            font-size: 1.8rem;
        }
        #emilia span, #agustina span, #monserrat span{
            font-size: 1.8rem;
        }
    }

    @media (max-width: 700px) {
        .div111 { width: 15.0rem;}
        #monserrat .div111{
            height: 10.0rem;
            width: auto;
        }
    }


    @media (max-width: 500px) {
        .testiContent{
            padding: 5rem 3rem;
        }
        #emilia, #agustina, #monserrat {
            grid-template-rows: 10.0rem auto;
            grid-row-gap: 1.0rem; 
        }
        .div111 { 
            width: 15.0rem;
            border-radius: 0;
        }
        #monserrat .div111{
            height: 10.0rem;
            width: auto;
        }
    }

    @media (max-width: 370px) {
        #emilia h2, #agustina h2, #monserrat h2{
            font-size: 1.8rem;
        }
        #emilia p, #agustina p, #monserrat p{
            font-size: 1.8rem;
        }
        #emilia span, #agustina span, #monserrat span{
            font-size: 1.8rem;
        }
    }